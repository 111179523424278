import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../Axios";
import { Link } from "react-router-dom";

export default function ProductDetail() {
  let { slug } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    Axios.get(`/data/s/${slug}`)
      .then((response) => {
        setProduct(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [slug]);

  if (!product) {
    return (
      <div className="flex justify-center md:flex-nowrap flex-wrap gap-20 pt-10 pb-16">
        <div className="md:h-[500px] w-[300px] h-[300px] md:w-[500px] bg-gray-300 animate-pulse rounded-lg cursor-zoom-in"></div>

        <div className="p-3 md:h-[500px] w-[300px] h-[300px] md:w-[500px]">
          <div className="bg-gray-300 h-8 w-3/4 mb-4 animate-pulse rounded-lg"></div>
          <div className="bg-gray-300 h-6 w-1/2 mb-4 animate-pulse rounded-lg"></div>
          <div className="bg-gray-300 h-4 w-2/3 mb-4 animate-pulse rounded-lg"></div>
          <div className="bg-gray-300 h-4 w-3/4 mb-4 animate-pulse rounded-lg"></div>
          <div className="bg-gray-300 h-4 w-2/5 animate-pulse rounded-lg"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center flex-wrap gap-5 md:gap-20 pt-10 pb-16">
      <div className="md:h-[500px] w-[300px] h-[300px] md:w-[500px] overflow-hidden rounded-lg cursor-zoom-in">
        <div className="md:h-[500px] w-[300px] h-[300px] md:w-[500px] transform hover:scale-110 transition-transform duration-700">
          <img
            src={product.thumbnail}
            alt="Thumbnail"
            className="h-full w-full object-cover"
          />
        </div>
      </div>

      <div className="p-3 w-[300px] md:w-[500px]">
        <h2 className="font-bold text-2xl md:text-3xl">{product.name}</h2>
        <p className="inline-block mt-3 bg-primary rounded-xl px-3 py-1 text-sm font-semibold text-white">
          {product.category.name}
        </p>

        <p className="text-slate-600 text-sm leading-4 mt-2">
          {product.description}
        </p>
        {product.specs.map((spec, i) => (
          <span
            key={i}
            className="inline-block mt-3  rounded-full border border-primary px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
          >
            #{spec}
          </span>
        ))}
        <p className="mt-2 flex items-center gap-2">
          {product.discountPrice < product.price ? (
            <>
              <span className="text-primary text-lg bg-white border-2 font-bold rounded-lg border-dotted py-1 px-2 md:py-2 md:px-3 border-primary">
                SAR {product.discountPrice}.00
              </span>
              <span className="text-lg line-through text-slate-600">
                SAR {product.price}.00
              </span>
            </>
          ) : (
            <span className="text-primary text-lg bg-white border-2 font-bold rounded-lg border-dotted py-1 px-2 md:py-2 md:px-3 border-primary">
              SAR {product.price}.00
            </span>
          )}
        </p>
        <Link
          to={product.purchase}
          className="flex items-center justify-center gap-2 w-36 bg-green-700 px-5 py-3 rounded-md text-white font-semibold mt-5 hover:bg-green-600 transition-colors duration-300 fill-current"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="14"
            viewBox="0 0 448 512"
            className="h-6 w-6"
          >
            <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
          </svg>
          Buy Now
        </Link>
      </div>
    </div>
  );
}
