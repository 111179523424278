import axios from "axios";

const Axios = axios.create({
baseURL: "https://al-zayan-server.vercel.app/api/v1.0",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    Authorization:`${localStorage.getItem('token')}`
  },
  withCredentials: true,

});

export default Axios;
