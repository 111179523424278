import React from "react";
import Slider from "react-slick";

function Carousel() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the auto-slide delay as needed (in milliseconds)
  };

  return (
    <section className="flex justify-center overflow-hidden">
      <div className="hidden lg:block w-screen mb-12">
        <Slider {...settings}>
          <img src="/slider.jpg" className="" alt="alzayan logo with a bottle of perfume"/>
          <img src="/slider.jpg" className="" alt="alzayan logo with a bottle of perfume"/>
          <img src="/slider.jpg" className="" alt="alzayan logo with a bottle of perfume"/>
        </Slider>
      </div>
      <div className="lg:hidden w-screen mb-12">
        <Slider {...settings}>
          <img src="/mobile.jpg" className="h-[91vh] object-cover" alt="alzayan logo with a bottle of perfume"/>
          <img src="/mobile.jpg" className="h-[91vh] object-cover" alt="alzayan logo with a bottle of perfume"/>
          <img src="/mobile.jpg" className="h-[91vh] object-cover" alt="alzayan logo with a bottle of perfume"/>
        </Slider>
      </div>
    </section>
  );
}

export default Carousel;
