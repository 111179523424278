import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function ShopByCategory() {
  return (
    <section className="md:bg-gray-100 flex justify-center items-center content-center flex-col ">
      <h1 className="text-center font-bold text-2xl md:text-3xl text-black md:mt-24 px-16">
        Shop Your Desired Products Here
      </h1>
      <div className="flex flex-wrap justify-center gap-4 p-8 md:p-16">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <Link to={"/athoorat"}>
            <div className="bg-bottom md:bg-[url('https://images.unsplash.com/photo-1535683577427-740aaac4ec25?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGVyZnVtZXxlbnwwfHwwfHx8MA%3D%3D')] bg-cover overflow-hidden md:h-96 md:w-72 rounded-xl flex flex-col justify-end">
              <div className="bg-primary px-3 py-2 md:p-5">
                <p className="text-lg md:text-xl font-bold text-white text-center">العطورات</p>
              </div>
            </div>
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <Link to={"/bakhoorat"}>
            <div className="bg-center md:bg-[url('https://img.freepik.com/premium-photo/traditional-arabic-oud-burner-white-background_410516-28539.jpg')] bg-cover overflow-hidden md:h-96 md:w-72 rounded-xl flex flex-col justify-end">
              <div className="bg-primary px-3 py-2 md:p-5">
                <p className="text-lg md:text-xl font-bold text-white text-center">البخورات
</p>
              </div>
            </div>
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <Link to={"/bakhoorat"}>
            <div className="bg-bottom md:bg-[url('https://burst.shopifycdn.com/photos/makeup-powder-foundation-brushes.jpg?width=925&format=pjpg&exif=0&iptc=0')] bg-cover overflow-hidden md:h-96 md:w-72 rounded-xl flex flex-col justify-end">
              <div className="bg-primary px-3 py-2 md:p-5">
                <p className="text-lg md:text-xl font-bold text-white text-center">التجميل</p>
              </div>
            </div>
          </Link>
        </motion.div>

      </div>
    </section>
  );
}

export default ShopByCategory;
